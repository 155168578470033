/* ========================================================================== */
/* TYPOGRAPHY
/* ========================================================================== */


@font-face {
    font-family: "NunitoLight";
    src: url('~src/assets/fonts/Nunito-Light.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoLightItalic";
    src: url('~src/assets/fonts/Nunito-LightItalic.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoRegular";
    src: url('~src/assets/fonts/Nunito-Regular.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoRegularItalic";
    src: url('~src/assets/fonts/Nunito-Italic.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoBold";
    src: url('~src/assets/fonts/Nunito-Bold.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoBoldItalic";
    src: url('~src/assets/fonts/Nunito-BoldItalic.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoBlack";
    src: url('~src/assets/fonts/Nunito-Black.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoBlackItalic";
    src: url('~src/assets/fonts/Nunito-BlackItalic.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoExtraBold";
    src: url('~src/assets/fonts/Nunito-ExtraBold.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoExtraBoldItalic";
    src: url('~src/assets/fonts/Nunito-ExtraBoldItalic.ttf') format("truetype"); /* IE9*/
}

@font-face {
    font-family: "NunitoExtraLight";
    src: url('~src/assets/fonts//Nunito-ExtraLight.ttf') format("truetype"); /* IE9*/
    // src: url("fonts/ab469ab49571a1009bffcfb2bf89d9e8.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    //      url("fonts/ab469ab49571a1009bffcfb2bf89d9e8.woff2") format("woff2"), /* chrome、firefox */
    //      url("fonts/ab469ab49571a1009bffcfb2bf89d9e8.woff") format("woff"), /* chrome、firefox */
    //      url("fonts/ab469ab49571a1009bffcfb2bf89d9e8.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    //      url("fonts/ab469ab49571a1009bffcfb2bf89d9e8.svg#InterFace W06 XBold Italic") format("svg"); /* iOS 4.1- */
}

@font-face {
    font-family: "SymbolaEmoji";
    src: url('~src/assets/fonts/Symbola-Emoji.woff') format("woff"),
         url('~src/assets/fonts/Symbola-Emoji.ttf') format("truetype");
}

$dark-green-color-code: #4c9202; // 76, 146, 2
$light-green-color-code: #75c900; // 117, 201, 0

$symphonie-dark-color: #8d3483; // 141, 52, 131
$symphonie-light-color: #c5168c; // 197, 22, 140


$harmony-blue-color: #00aee9; // 0, 174, 233
$harmony-green-color: #69fabd; // 105, 250, 189
$harmony-cool-gray: #758796; // 117, 135, 150

$dark-gray-color-code: #595959;
$medium-gray-color-code: #a9a9a9; // 169, 169, 169
$light-gray-color-code: #dadce0; // 218, 220, 224

$basic-font-family: 'NunitoRegular', sans-serif;

$body-font-size: 14px;

$navbar-height: 64px;

$border-radius: 10px;