/* ========================================================================== */
/* SASS
/* ========================================================================== */


@import '~sass/variables';
@import '~sass/mixins';


/* ========================================================================== */
/* GENERAL
/* ========================================================================== */


html, body { height: 100%; height: -webkit-fill-available; }
body {
    margin: 0;
    background-color: #0b0244;
    background-image: radial-gradient(#0b0244, #0b021f);
}


/* ========================================================================== */
/* FONTS
/* ========================================================================== */


@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400&display=swap');
// @import url('https://use.fontawesome.com/releases/v5.15.3/css/all.css');


/* ========================================================================== */
/* MATERIAL CLASSES
/* ========================================================================== */


.mat-snack-bar-container,
.tothemoon-snackbar-container{
    background: #fff !important;
}

/* Navbar profile
   ========================================================================== */

.mat-menu-panel.profile-menu{
    width: 180px;
}

.mat-progress-bar.epoch-state__progress .mat-progress-bar-element{
    background-color: rgba(141, 52, 131, .5);
}

.mat-progress-bar.epoch-state__progress .mat-progress-bar-fill::after{
    background-color: $symphonie-light-color;
}

/* Validator modal
   ========================================================================== */

.validator-modal{
    &__information{
        .summary{
            &__field{
                .mat-form-field-wrapper{
                    padding-bottom: 0 !important;
                }
            }
        }
    }
}

/* Delegation accordion
   ========================================================================== */

.mat-expansion-panel-header-description.delegation-accordion__description{
    flex-grow: 0;
}

.mat-expansion-panel-header.delegation-accordion__header {
    font-size: 12px;
    font-family: 'NunitoRegular';
    position: relative;
}

.mat-expansion-panel-header.delegation-accordion__header{
    &--in{
        .delegation-accordion__description span{
            min-width: 80px;
            color: green;
            text-align: right;
        }
    }
    &--out{
        .delegation-accordion__description span{
            min-width: 80px;
            color: red;
            text-align: right;
        }    
    }
    &.today{
        &::after{
            content: '';
            position: absolute;
            width: 4px;
            height: 100%;
            background-color: $symphonie-light-color;
            top: 0;
            left: 0;
        }
    }
}

/* Percentage progress bar
   ========================================================================== */

.signing-percentage-desktop{
    .mat-progress-bar-buffer{
        background-color: #dadce0;
    }
    .mat-progress-bar-fill{
        &::after{
            background-color: #75c900;
        }
    }
}

/* Pie tab group
   ========================================================================== */

.validator-page{
    &__percent-pie{
        .mat-tab-group.categories-wrapper{
            .mat-tab-body{
                min-height: 325px;
            }
            .mat-tab-body-content{
                min-height: 325px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .mat-tab-header{
                border: none;
                .mat-tab-label-container{
                    .mat-tab-list{
                        .mat-tab-labels{
                            padding: 0 4px;
                            width: fit-content;
                            margin: 0 auto;
                            background-color: #e1e1e1;
                            box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, .2);
                            cursor: pointer;
                            border-radius: 30px;
                            .mat-tab-label{
                                width: 50%;
                                font-size: .8rem;
                            }
                            .mat-ripple.mat-tab-label-active{
                                z-index: 9;
                                opacity: 1;
                            }
                        }
                        .mat-ink-bar{
                            background-color: #fff;
                            z-index: 1;
                            height: 40px;
                            border-radius: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
                        }
                    }
                }
                .mat-tab-header-pagination{
                    // display: none;
                }
            }
        }
    }
    @media all and (max-width: 1199px) {
        &__percent-pie{
            .mat-tab-group.categories-wrapper{
                .mat-tab-body{
                    min-height: 290px;
                }
                .mat-tab-body-content{
                    min-height: 290px;
                }
            }
        }
    }
    @media all and (max-width: 991px) {
        &__percent-pie{
            .mat-tab-group.categories-wrapper{
                .mat-tab-body{
                    min-height: 325px;
                }
                .mat-tab-body-content{
                    min-height: 325px;
                }
            }
        }
    }
    @media all and (max-width: 767px) {
        &__percent-pie{
            .mat-tab-group.categories-wrapper{
                .mat-tab-body{
                    min-height: 300px;
                }
                .mat-tab-body-content{
                    min-height: 300px;
                }
            }
        }
    }
    @media all and (max-width: 568px) {
        &__percent-pie{
            .mat-tab-group.categories-wrapper{
                // .mat-tab-body{
                //     min-height: 395px;
                // }
                .mat-tab-body-content{
                    // min-height: 395px;
                    flex-direction: column;
                }
                .mat-tab-header{
                    .mat-tab-label-container{
                        .mat-tab-list{
                            max-width: 100%;
                            .mat-tab-labels{
                                width: 100%;
                                .mat-tab-label{
                                    font-size: .7rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* ========================================================================== */
/* GENERAL DEFINITIONS
/* ========================================================================== */


i{
    &.elected-sign{
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 10px;
        color: $harmony-cool-gray;
        margin: 5px;
        &.elected{
            color: green;
            box-shadow: 0 0 10px 0px rgba(117, 201, 0, 1);
            border-radius: 10px;
        }
    }
}


/* ========================================================================== */
/* VALIDATOR PAGE
/* ========================================================================== */


.elected-slider{
    &__container{
        .swiper-pagination{
            text-align: left;
            bottom: 0;
            color: $symphonie-light-color;
            span.swiper-pagination-current{
                font-weight: 700;
            }
        }
    }
}
  
.swiper-button-next,
.swiper-button-prev{
    width: 15px;
    height: 15px;
    bottom: 5px;
    top: auto;
    right: 0;
    background-size: 15px 15px;
    z-index: 18;
    background-image: url('./assets/images/arrow-right-model-a-dark-violet.svg');
}

.swiper-button-prev{
    right: 30px;
    left: auto;
    background-image: url('./assets/images/arrow-left-model-a-dark-violet.svg');
}

.validator-summary__name{
    .mat-badge-content{
        opacity: .6;
        background: $harmony-cool-gray !important;
        width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
        top: -3px !important;
        left: -8px !important;
    }
    &.elected{
        .mat-badge-content{
            background: green !important;
        }
    }
}

.categories-wrapper__paginator{
    width: 100%;
    margin-bottom: 30px;
}

/* Dashboard validator table
   ========================================================================== */

.user-dashboard{
    &__filter-input{
        width: 100%;
    }
    &__th-row{
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        &:first-of-type{
            padding-left: 24px;
        }
        &:last-of-type{
            padding-right: 24px;
        }
        &.name-column,
        &.status-column{
            text-align: left;
        }
    }
}

/* Validator page
   ========================================================================== */

#DataTables_Table_0_wrapper{
    width: 100% !important;
    .mdc-layout-grid.dt-table{
        width: 100%;
    }
}

div.dataTables_wrapper div.dataTables_length select{
    min-width: 120px;
    margin: 0 18px;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]{
    padding: 0;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label{
    display: flex;
    align-items: center;
}

div.dataTables_wrapper div.dataTables_filter input{
    width: 100%;
}

// div.table-responsive > div.dataTables_wrapper > div.row:first-child{
//     position: fixed;
//     top: 64px;
//     height: 40px;
//     z-index: 9999;
//     background: blue;
//     width: 100%;
// }
// div.table-responsive > div.dataTables_wrapper > div.row:nth-child(2){
//     padding-top: 40px;
// }

.pill-box{
    background-color: #f8f9fa;
    padding: 1rem;
    border: 1px solid #e9ecef;
    border-radius: .25rem;
    &__headline{
        color: $harmony-cool-gray;
        opacity: .6;
        font-size: 80%;
        font-weight: 400;
    }
    &__text{
        color: $harmony-cool-gray;
    }
}

.info-box{
    background-color: #f8f9fa;
    padding: 1rem 1.5rem;
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #e9ecef;
    border-left-width: .25rem;
    border-radius: .25rem;
    &--info{
        border-left-color: $harmony-blue-color;
    }
    &--note{
        border-left-color: $harmony-cool-gray;
    }
    &__headline{
        color: $harmony-cool-gray;
        opacity: .6;
    }
    &__text{
        color: $harmony-cool-gray;
    }
}


.bookmark-tooltip{
    position: relative;
    display: inline-block;
    &::after{
        content: 'Follow';
        position: absolute;
        visibility: hidden;
        width: auto;
        background-color: rgba(0,0, 0, .55);
        color: #fff;
        text-align: center;
        padding: 10.5px;
        border-radius: 4px;
        z-index: 1;
        font-family: "Helvetica Neue", sans-serif;
        letter-spacing: normal;
        top: 147%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
    }
    &.following{
        &::after{
            content: 'Unfollow';
        }
    }
    &:hover{
        cursor: pointer;
        &::after{
            visibility: visible;
        }
    }
}

.wallet-address{
    position: relative;
    display: inline-block;
    &::after{
        content: 'Copy Address';
        position: absolute;
        visibility: hidden;
        width: auto;
        background-color: rgba(0,0, 0, .55);
        color: #fff;
        text-align: center;
        padding: 10.5px;
        border-radius: 4px;
        z-index: 1;
        font-family: "Helvetica Neue", sans-serif;
        letter-spacing: normal;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
    }
    &.copied{
        &::after{
            content: 'Copied!';
        }
    }
    &:hover{
        cursor: pointer;
        &::after{
            visibility: visible;
        }
    }
}

.symphonie-home{
    overflow: hidden !important;
}

.symphonie-screenshot{
    width: 800px;
    height: 480px;
    display: none;
    position: fixed;
    z-index: -1;
    &__bg{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    &__earth{
        height: auto;
        position: absolute;
        top: 55%;
        right: 53%;
        z-index: 2;
        .earth-element{
            border-radius: 50%;
            position: relative;
            z-index: 80;
            img{
                width: 100%;
                border-radius: 50%;
            }    
        }
        .earth-dot{
            background-color: #fff;
            border-radius: 50%;
            width: 5px;
            height: 5px;
            position: relative;
            top: 40px;
            right: 80px;
            z-index: 80;
            &::before{
                content: '';
                position: absolute;
                height: 25vh;
                width: 100px;
                background: transparent;
                border-left: 1px solid rgba(255, 255, 255, .4);
                border-top: 1px solid rgba(255, 255, 255, .4);
                bottom: 100%;
                left: 2px;
                opacity: .55;
            }
            &::after{
                content: 'Earth';
                position: absolute;
                color: #fff;
                bottom: 24vh;
                font-size: 10px;
                left: 105px;
            }
        }
    }
    &__logo{
        z-index: 3;
        position: absolute;
        z-index: 100;
        display: inline-block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 5px solid rgba(255, 255, 255, 0);
        border-radius: 50%;
        padding: 40px;
        transition: width 0.25s linear;
        box-shadow: 5px 10px 18px rgba(255, 255, 255, 0);
        width: 208px;
        .circle-border{
            width: 136.5px;
            height: 136.5px;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #3ff9dc;
            background: linear-gradient(0deg, rgba(63, 249, 220, 0.1) 33%, #3ff9dc 100%);
            animation: spin 0.8s linear 0s infinite;
            position: absolute;
            display: none;
            top: 0;
            left: 0;
        }
        .logo-image{
            width: 100%;
            vertical-align: middle;
            border-style: none;
        }
    }
    &__pirce-ticker{
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 30px;
        color: #fff;
        .price-ticker__lable{
            font-family: "NunitoRegular", sans-serif;
        }
    }
    &__staking{
        position: absolute;
        z-index: 10;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #fff;
        .staking-state{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 30px;
        }
    }
}


.screenshot-popup{
    position: relative;
    top: 0;
    left: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color .15s linear;
    &__modal{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        #output{
            background: #0b021f;
            canvas{
                width: 100% !important;
                height: auto !important;    
            }
        }
    }
    &.show{
        background-color: rgba(0, 0, 0, .5);
        z-index: 999998;
        position: fixed;
        width: 100vw;
        height: 100vh;
        .screenshot-popup__modal{
            position: absolute;
            width: 800px;
            height: 800px;
            max-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #0b021f;
            canvas{
                width: 100% !important;
                height: auto !important;    
            }
        }    
    }
}


@media all and (max-width: 767px) {
    .bookmark-tooltip{
        &:hover{
            cursor: default;
            &::after{
                visibility: hidden;
            }    
        }    
    }
    .tothemoon-snackbar-container{
        margin-bottom: 65px !important;
    }    
}
